import { memo } from "react";
import DatePicker from "react-date-picker";
import IconResource from "../../../Wrapper/IconResource/IconResource";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Form, InputGroup } from "react-bootstrap";

const DatePickerWrapper = memo((props) => {
  const {
    dateRef,
    value,
    onChange,
    label,
    placeholderText = "DD/MM/YYYY",
    labelColor,
    isRequired,
    ...rest
  } = props;

  return (
    <div className="date-picker">
      {label && (
        <Form.Label
          className={isRequired ? "required" : ""}
          aria-label="datepicker-label"
          style={{ color: labelColor }}
        >
          {label}
        </Form.Label>
      )}
      <InputGroup className="mb-3">
        <DatePicker
          ref={dateRef}
          value={value}
          onChange={onChange}
          format="dd/MM/yyyy"
          clearIcon={null}

          calendarIcon={<IconResource type="calendar" color="var(--primary)" />}
          placeholderText={placeholderText}
          {...rest}
        />
      </InputGroup>
    </div>
  );
});

export default DatePickerWrapper;