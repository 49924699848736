import React from "react";
import AnimatedPage from "../../AnimatedPage/AnimatedPage";
import NavBar from "../../_widgets/NavBar/NavBar";
import CustomTooltip from '../../_widgets/Tooltip/CustomTooltip';
import * as stringUtils from "../../../Utils/stringUtils";
import CustomPagination from '../../_widgets/Pagination/Pagination';
import Text from '../../_widgets/Text/Text';
import Button from '../../_widgets/Button/Button';
import IconResource from '../../../Wrapper/IconResource/IconResource';
import { Table, Col, Row, Card } from 'react-bootstrap';
import noData from "../../../Assets/img/noData.png";
import { formattedValue, isSuperAdmin } from '../../../Services/common';
import MainLoader from '../../_widgets/Loader/MainLoader';
import DateRangePicker from '../../_widgets/DateRangePicker/DateRangePicker';
import Input from '../../_widgets/Input/Input';
import AddEditExpense from "./AddEditExpense";
import { changeDateFormatForDisplay } from "../../../Utils/dateUtils";
const ExpenseUi = ({ searchQuery, setSearchQuery, activePage, dates, setDates, datePickerStartRef, datePickerEndRef, loading, buttonLoading, setModalShow, onPageChange, expenseList, getExpenseList, setExpenseData, modelShow, handleSave, handleClose, expenseData, fetchExpensiveCategory, getExpenseCategoryList, setCategorySearch, deleteMedicine, modalLoading, selectLoading }) => {

    return (
        <>
            <AnimatedPage>
                <AddEditExpense
                    selectLoading={selectLoading}
                    buttonLoading={buttonLoading}
                    loading={modalLoading}
                    handleSave={handleSave}
                    modelShow={modelShow}
                    handleClose={handleClose}
                    expenseData={expenseData}
                    setExpenseData={setExpenseData}
                    getExpenseCategoryList={getExpenseCategoryList}
                    setCategorySearch={setCategorySearch}
                    fetchExpensiveCategory={fetchExpensiveCategory} />
                <NavBar

                    button="Add Expense"
                    buttonIcon="hospital"
                    heading="Expense"
                    onClick={() => {
                        document.body.classList.toggle("side_active");
                        document.body.classList.toggle("side_active_mb");
                    }}
                    setModalShow={setModalShow}

                    Icon="menu"
                />

                <div className="patient_list white_card">
                    <div className="top_bar">
                        <Text type="h2" text="All Expense" />
                        <Row className="search_filter">
                            <Col lg={4}>
                                <div>
                                    <Input
                                        placeholder="Search"
                                        id="searchInput"
                                        suffixIcon='search'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onClickIconSuff={() => getExpenseList()}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getExpenseList()
                                            }
                                        }}
                                        iconTooltip='Search'
                                    />
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="date_range_picker">
                                    <DateRangePicker
                                        startDate={dates?.startDate}
                                        setStartDate={(startDate) => setDates({ ...dates, startDate })}
                                        endDate={dates?.endDate}
                                        setEndDate={(endDate) => setDates({ ...dates, endDate })}
                                        onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                                        datePickerStartRef={datePickerStartRef}
                                        datePickerEndRef={datePickerEndRef}
                                        showDateShortcuts={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <Row className="my-5 justify-content-between mx-0">
                        <Col className="p-1" sm={12} md={6} lg={2}>
                            <Card className="p-1" style={{
                                height: '150px', width: '100%', border: "1px solid #5e81f421", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px"
                            }}>
                                <div className="d-flex justify-content-center align-items-center flex-column gap-1 h-100">
                                    {/* <Text text={isSuperAdmin() ? 'Active' : 'At Counsellor'} /> */}
                                    <Text text={'Total Till Date'} />
                                    <Text type='h5' text={formattedValue(expenseList?.stats?.tillDate || 0, true, true)} />
                                </div>
                            </Card>
                        </Col>
                        <Col className="p-1" sm={12} md={6} lg={2}>
                            <Card className="p-1" style={{ height: '150px', width: '100%', border: "1px solid #5e81f421", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px" }}>
                                <div className="d-flex justify-content-center align-items-center flex-column gap-1 h-100">
                                    {/* <Text text={isSuperAdmin() ? 'Total' : 'At Doctor'} /> */}
                                    <Text text={'Total This Year'} />
                                    <Text type='h5' text={formattedValue(expenseList?.stats?.thisYear || 0, true, true)} />
                                </div>
                            </Card>
                        </Col>
                        <Col className="p-1" sm={12} md={6} lg={2}>
                            <Card className="p-1" style={{ height: '150px', width: '100%', border: "1px solid #5e81f421", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px" }}>
                                <div className="d-flex justify-content-center align-items-center flex-column gap-1 h-100">
                                    {/* <Text text={isSuperAdmin() ? 'Expiring Next Month' : 'At Pharmacy'} /> */}
                                    <Text text={'Total This Month'} />
                                    <Text type='h5' text={formattedValue(expenseList?.stats?.thisMonth || 0, true, true)} />
                                </div>
                            </Card>
                        </Col>
                        <Col className="p-1" sm={12} md={6} lg={2}>
                            <Card className="p-1" style={{ height: '150px', width: '100%', border: "1px solid #5e81f421", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px" }}>
                                <div className="d-flex justify-content-center align-items-center flex-column gap-1 h-100">

                                    <Text text={'Total Last Month'} />
                                    <Text type='h5' text={formattedValue(expenseList?.stats?.lastMonth || 0, true, true)} />
                                </div>
                            </Card>
                        </Col>
                        <Col className="p-1" sm={12} md={6} lg={2}>
                            <Card className="p-1" style={{ height: '150px', width: '100%', border: "1px solid #5e81f421", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "12px" }}>
                                <div className="d-flex justify-content-center align-items-center flex-column gap-1 h-100">
                                    {/* <Text text={isSuperAdmin() ? 'New In Current Month' : 'Total'} /> */}
                                    <Text text={'Total Selected Date'} />
                                    <Text type='h5' text={formattedValue(expenseList?.stats?.selectedDateRange || 0, true, true)} />
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div className="custom_table position-relative">
                        {loading ? <MainLoader /> : expenseList?.data?.data?.length > 0 ? (
                            <>
                                <div>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th>Category</th>
                                                <th>Date </th>
                                                <th>Amount </th>
                                                <th>Remark  </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {expenseList?.data?.data?.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <CustomTooltip text={data.category} placement={"top"}>
                                                                {data?.expense_category?.name || ''}
                                                            </CustomTooltip>
                                                        </td>
                                                        <td>
                                                            <CustomTooltip text={changeDateFormatForDisplay(data?.date)} placement={"top"}>
                                                                {changeDateFormatForDisplay(data?.date)}
                                                            </CustomTooltip>
                                                        </td>
                                                        <td>
                                                            <CustomTooltip text={formattedValue(data.amount, true, true)} placement={"top"}>
                                                                {formattedValue(data?.amount, true, true)}
                                                            </CustomTooltip>
                                                        </td>
                                                        <td>
                                                            <CustomTooltip text={data.description | "-"} placement={"top"}>
                                                                {data?.description || "-"}
                                                            </CustomTooltip>
                                                        </td>

                                                        <td>
                                                            <div className="d-flex align-items-center gap-2 justify-content-center">
                                                                <CustomTooltip text="Edit" placement={"top"}>
                                                                    <Button
                                                                        className="p-1 bg-transparent border-0"
                                                                        icon={
                                                                            <IconResource type={buttonLoading === `edit${data.id}` ? 'loader' : 'edit'} color="var(--light-text)" />
                                                                        }
                                                                        onClick={() => {
                                                                            setExpenseData(data)
                                                                            setModalShow(true)
                                                                        }}
                                                                    />
                                                                </CustomTooltip>
                                                                <CustomTooltip text="Delete" placement={"top"}>
                                                                    <Button
                                                                        className="p-1 bg-transparent border-0"
                                                                        icon={<IconResource type="trash" color="red" />}
                                                                        onClick={() => deleteMedicine(data.id)}
                                                                    />
                                                                </CustomTooltip>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>

                                <CustomPagination
                                    activePage={expenseList?.data?.current_page}
                                    onPageChange={onPageChange}
                                    pageLength={expenseList?.data?.per_page}
                                    totalData={expenseList?.data?.total}
                                    lastPage={expenseList?.data?.last_page}
                                />
                            </>
                        ) : (
                            <>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th>Category</th>
                                            <th>Date </th>
                                            <th>Amount </th>
                                            <th>Remark  </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody></tbody>
                                </Table>

                                <div className="no_data position-relative">
                                    <img
                                        src={noData}
                                        className="img-fluid"
                                        style={{ width: "300px" }}
                                    />
                                    <Text
                                        type="h2"
                                        text="There is no data to display. Try re-setting filters!"
                                    />
                                    <Button
                                        buttonType="primary"
                                        text="Add Expense"
                                        prefixIcon={<IconResource type="hospital" size={18} />}
                                        onClick={() => setModalShow(true)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </AnimatedPage>
        </>
    )
}
export default ExpenseUi;