import React, { useState, useRef, useEffect } from "react";
import ExpenseUi from "./ExpenseUi";
import * as apiHelper from "../../../Services/ApiHelper";
import * as commonServices from "../../../Services/common";
import { changeDateFormatForServer } from '../../../Utils/dateUtils';
import * as toast from "../../../Wrapper/toast/toast";
import { AMOUNT_REQUIRED, CATEGORY_REQUIRED, CN_AMOUNT_REQUIRED, DESC_REQUIRED, EXPENSE_DATE_REQUIRED, NAME_REQUIRED, PRODUCT_ID_REQUIRED, REMARKS_REQUIRED } from '../../../Constants/stringConstants';
import { isNullOrEmpty } from '../../../Utils/stringUtils';
import { ACTIVE } from "../../../Constants/appConstants";
const Expense = () => {
    const initialExpenseData = {
        amount: '',
        description: '',
        date: '',
        exp_cat_id: ''
    }
    const [activePage, setActivePage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("")
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [loading, setLoading] = useState(false)
    const [modelShow, setModalShow] = useState(false)
    const [modalLoading, setModalLoading] = useState(false)

    const [expenseList, setExpenseList] = useState([])
    const [expenseData, setExpenseData] = useState(initialExpenseData)
    const [expenseCategoryList, setExpenseCategoryList] = useState([])
    const [categorySearch, setCategorySearch] = useState("")
    const [selectLoading, setSelectLoading] = useState(false)
    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();
    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getExpenseList();
        }
    }, [activePage, dates]);
    useEffect(() => {
        getExpenseCategoryList(1, "noloading")
    }, [modelShow])
    useEffect(() => {
        fetchExpensiveCategory()
    }, [expenseCategoryList])
    useEffect(() => {
        const timer = setTimeout(() => {

            getExpenseCategoryList();  // Trigger API call with updated categorySearch

        }, 250);  // 250ms delay

        return () => clearTimeout(timer);  // Cleanup the timeout if categorySearch changes again before the timer expires
    }, [categorySearch]);  // This effect runs every time categorySearch changes

    const fetchExpensiveCategory = () => {
        let expensiveCategory = []
        expenseCategoryList?.data?.map((i) => {

            if (i?.status === ACTIVE) {
                let data = {
                    label: i.name,
                    value: i.id
                }
                expensiveCategory.push(data)
            }
        })
        return expensiveCategory;
    }

    const getExpenseCategoryList = (page = 1, loading) => {
        if (loading != "noloading") {
            setModalLoading(false)
            setSelectLoading(true)
        }
        else {
            setModalLoading(true)
            setSelectLoading(false)
        }
        let query = `?page=${page}&limit=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&q=${categorySearch}`;

        apiHelper.getExpenseCategoryList(query).then((response) => {

            if (response?.isSuccess) {
                setSelectLoading(false)
                setExpenseCategoryList(response.data);
            } else {
                setSelectLoading(false)
                toast.warn(response?.message);
            }
        }).finally(() => setModalLoading(false));
    };
    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };
    const getExpenseList = (page = 1) => {
        let query = `?page=${activePage}&limit=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&q=${searchQuery}`;
        setLoading(true);

        apiHelper.getExpenseList(query).then((response) => {
            if (response?.isSuccess) {
                setExpenseList(response.data);
            } else {
                toast.warn(response?.message);
            }
        }).finally(() => setLoading(false));
    };
    const validateData = () => {
        const { amount, date, exp_cat_id, description } = expenseData;

        // Validation for 'Name'
        if (isNullOrEmpty(amount)) {
            toast.warn(AMOUNT_REQUIRED);
            return false;
        }
        if (isNullOrEmpty(exp_cat_id)) {
            toast.warn(CATEGORY_REQUIRED);
            return false;
        }

        if (isNullOrEmpty(date)) {
            toast.warn(EXPENSE_DATE_REQUIRED);
            return false;
        } if (isNullOrEmpty(description)) {
            toast.warn(REMARKS_REQUIRED);
            return false;
        }






        return true;
    };
    const handleSave = () => {
        if (validateData()) {
            setButtonLoading(true)
            const { amount, description, date, exp_cat_id, id } = expenseData;
            const data = new FormData();
            data.append('amount', amount);
            data.append('description', description);
            data.append('date', changeDateFormatForServer(date));
            data.append('exp_cat_id', exp_cat_id);
            if (id) {
                apiHelper.editExpense(id, data).then((response) => {
                    setButtonLoading(false);
                    if (response?.isSuccess) {

                        getExpenseList()
                        commonServices.fetchSystemData();
                        toast.success(response.message);
                        handleClose()
                        setButtonLoading(false)
                        setModalShow(false);
                        clearData();
                    } else {
                        setButtonLoading(false)
                        handleClose()
                        setModalShow(false);
                        clearData();
                        toast.warn(response?.message);
                    }
                }).catch((error) => {
                    setButtonLoading(false)
                    handleClose()
                    setModalShow(false);
                    clearData();
                    toast.warn(error.message);
                });
            }
            else {
                apiHelper.addExpense(data).then((response) => {
                    setButtonLoading(false);
                    if (response?.isSuccess) {
                        getExpenseList()
                        commonServices.fetchSystemData();
                        toast.success(response.message);
                        handleClose()
                        setButtonLoading(false)
                        setModalShow(false);
                        clearData();
                    } else {
                        setButtonLoading(false)
                        handleClose()
                        setModalShow(false);
                        clearData();
                        toast.warn(response?.message);
                    }
                }).catch((error) => {
                    toast.warn(error.message);
                    handleClose()
                    setButtonLoading(false)
                    setModalShow(false);
                    clearData();
                });
            }
        }

    }
    const handleClose = () => {
        setExpenseData(initialExpenseData)
        setModalShow(false);
        setCategorySearch("")
        setExpenseCategoryList([])
    };
    const clearData = () => {
        setExpenseData(initialExpenseData)
        setExpenseCategoryList([])
    }
    const deleteMedicine = (id) => {
        setLoading(true)
        apiHelper.deleteExpense(id).then((response) => {

            if (response?.isSuccess) {

                getExpenseList()
                commonServices.fetchSystemData();
                toast.success(response.message);

            } else {
                getExpenseList()
                commonServices.fetchSystemData();
                toast.warn(response?.message);


            }
        });
    };
    return (<>
        <ExpenseUi
            setSearchQuery={setSearchQuery}
            searchQuery={searchQuery}
            activePage={activePage}
            dates={dates}
            setDates={setDates}
            loading={loading}
            buttonLoading={buttonLoading}
            modelShow={modelShow}
            setModalShow={setModalShow}
            datePickerStartRef={datePickerStartRef}
            datePickerEndRef={datePickerEndRef}
            onPageChange={onPageChange}
            expenseList={expenseList}
            getExpenseList={getExpenseList}
            setExpenseData={setExpenseData}
            handleSave={handleSave}
            handleClose={handleClose}
            expenseData={expenseData}
            fetchExpensiveCategory={fetchExpensiveCategory}
            getExpenseCategoryList={getExpenseCategoryList}
            setCategorySearch={setCategorySearch}
            deleteMedicine={deleteMedicine}
            modalLoading={modalLoading}
            selectLoading={selectLoading}
        />
    </>)
}
export default Expense;