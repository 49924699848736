import React, { useRef, useState, useEffect } from 'react';
import ExpenseCategoryUi from './ExpenseCategoryUi';
import * as apiHelper from "../../../Services/ApiHelper";
import * as commonServices from "../../../Services/common";
import { changeDateFormatForServer } from '../../../Utils/dateUtils';
import * as toast from "../../../Wrapper/toast/toast";
import { DESC_REQUIRED, NAME_REQUIRED } from '../../../Constants/stringConstants';
import { isNullOrEmpty } from '../../../Utils/stringUtils';
const ExpenseCategory = () => {
    const initialExpenseCategoryData = {
        name: '',
        description: '',
        id: null
    }
    const [modelShow, setModalShow] = useState(false)
    const [expenseCategoryList, setExpenseCategoryList] = useState([])
    const [loading, setLoading] = useState(false)
    const [activePage, setActivePage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("")
    const [dates, setDates] = useState({ startDate: null, endDate: null });
    const [buttonLoading, setButtonLoading] = useState(false);
    const [expenseCategoryData, setExpenseCategoryData] = useState(initialExpenseCategoryData);
    const [importModal, setImportModal] = useState(false);
    const [importList, setImportList] = useState();
    const [selectedRows, setSelectedRows] = useState([])
    const [selectedCategoryTypes, setSelectedCategoryTypes] = useState([]);
    const [lastSelectedPage, setLastSelectedPage] = useState(1);
    const datePickerStartRef = useRef();
    const datePickerEndRef = useRef();
    useEffect(() => {
        if ((dates.startDate !== null && dates.endDate !== null) || (dates.startDate === null && dates.endDate === null)) {
            getExpenseCategoryList();
        }
    }, [activePage, dates]);

    const onPageChange = (page) => {
        if (page !== activePage) {
            setActivePage(page);
        }
    };
    const getExpenseCategoryList = (page = 1) => {
        let query = `?page=${activePage}&limit=10&start_date=${changeDateFormatForServer(dates.startDate)}&end_date=${changeDateFormatForServer(dates.endDate)}&q=${searchQuery}`;
        setLoading(true);
        setLastSelectedPage(page)
        if (commonServices.isAdmin() || commonServices.isSuperAdmin()) {
            selectedCategoryTypes.forEach((s, i) => {
                query += `&service_type[${i}]=${s}`;
            });
        }
        apiHelper.getExpenseCategoryList(query).then((response) => {
            if (response?.isSuccess) {
                setExpenseCategoryList(response.data);
            } else {
                toast.warn(response?.message);
            }
        }).finally(() => setLoading(false));
    };
    const handleClose = () => {
        setExpenseCategoryData(initialExpenseCategoryData)
        setModalShow(false);
    };
    const validateData = () => {
        const { name, description } = expenseCategoryData;

        // Validation for 'Name'
        if (isNullOrEmpty(name)) {
            toast.warn(NAME_REQUIRED("Expense Category"));
            return false;
        }
        // Validation for 'Description'
        if (isNullOrEmpty(description)) {
            toast.warn(DESC_REQUIRED);
            return false;
        }





        return true;
    };
    const handleSave = () => {
        if (validateData()) {
            setButtonLoading(true)
            const { name, description, id } = expenseCategoryData;
            const data = new FormData();
            data.append('name', name);
            data.append('description', description);
            if (id) {
                apiHelper.editExpenseCategory(id, data).then((response) => {

                    if (response?.isSuccess) {
                        getExpenseCategoryList()
                        setExpenseCategoryData(initialExpenseCategoryData)
                        commonServices.fetchSystemData();
                        toast.success(response.message);
                        setButtonLoading(false)
                        setModalShow(false);

                        clearData();
                    } else {
                        setButtonLoading(false)
                        setExpenseCategoryData(initialExpenseCategoryData)
                        setModalShow(false);
                        toast.warn(response?.message);
                    }
                }).catch((error) => {
                    setButtonLoading(false)
                    setExpenseCategoryData(initialExpenseCategoryData)
                    setModalShow(false);
                    toast.warn(error.message);
                });
            }
            else {
                apiHelper.addExpenseCategory(data).then((response) => {

                    if (response?.isSuccess) {
                        getExpenseCategoryList()
                        commonServices.fetchSystemData();
                        toast.success(response.message);
                        setButtonLoading(false)
                        setExpenseCategoryData(initialExpenseCategoryData)
                        setModalShow(false);
                        clearData();
                    } else {
                        toast.warn(response?.message);
                        setButtonLoading(false)
                        setExpenseCategoryData(initialExpenseCategoryData)
                        setModalShow(false);
                    }
                }).catch((error) => {
                    toast.warn(error.message);
                    setButtonLoading(false)
                    setExpenseCategoryData(initialExpenseCategoryData)
                    setModalShow(false);
                });
            }
        }

    }
    const clearData = () => {
        setExpenseCategoryData(initialExpenseCategoryData)
    }
    const statusHandler = (data) => {
        const updatedStatus = data.status === "ACTIVE" ? "INACTIVE" : "ACTIVE";
        setExpenseCategoryData({ ...expenseCategoryData, status: updatedStatus });
        setButtonLoading(true);
        apiHelper.changeExpenseCategoryStatus(data.id, { status: updatedStatus }).then((response) => {
            setButtonLoading(false);
            if (response?.isSuccess) {
                getExpenseCategoryList();
                commonServices.fetchSystemData();
                toast.success(response.message);
                setModalShow(false);
            } else {
                toast.warn(response?.message);
            }
        });
    };
    const openImportModal = () => {
        setImportModal(true);
    }
    const importListing = (data) => {

        if (importModal) {
            setLoading(true)
            apiHelper.importMaster("expense_category", data).then((response) => {
                if (response?.isSuccess) {
                    setImportList(response?.data);
                }
            }).catch(err => toast.warn(err)).finally(() => {
                setLoading(false)
            });
        }
    }
    const exportList = () => {
        setButtonLoading(true)
        apiHelper.exportMaster("expense_category", { ids: selectedRows }).then((response) => {
            if (response?.isSuccess) {
                toast.success(response?.message)
                getExpenseCategoryList(lastSelectedPage);
                commonServices.fetchSystemData(setLoading);
            } else {
                toast.warn(response.message)
            }
        }).catch(err => toast.warn(err)).finally(() => {
            setButtonLoading(false)
            setImportModal(false)
        })
    }
    return (
        <>

            <ExpenseCategoryUi
                setModalShow={setModalShow}
                expenseCategoryList={expenseCategoryList}
                modelShow={modelShow}
                loading={loading}
                onPageChange={onPageChange}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                getExpenseCategoryList={getExpenseCategoryList}
                dates={dates}
                setDates={setDates}
                datePickerStartRef={datePickerStartRef}
                buttonLoading={buttonLoading}
                datePickerEndRef={datePickerEndRef}
                setExpenseCategoryData={setExpenseCategoryData}
                handleClose={handleClose}
                handleSave={handleSave}
                expenseCategoryData={expenseCategoryData}
                statusHandler={statusHandler}
                openImportModal={openImportModal}
                importModal={importModal}
                setImportModal={setImportModal}
                importList={importList}
                importListing={importListing}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                exportList={exportList}
                setSelectedCategoryTypes={setSelectedCategoryTypes}
            />
        </>
    )
}
export default ExpenseCategory;