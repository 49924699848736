import React from 'react';
import BasicModal from '../../_widgets/Modal/BasicModal';
import { MAX_AMT_LIMIT_REACHED, maximumLengthAllowed } from '../../../Constants/stringConstants';
import { Row, Col } from 'react-bootstrap';
import { warn } from '../../../Wrapper/toast/toast';
import Input from '../../_widgets/Input/Input';
import { amountFormatter } from '../../../Services/common';
import { isNullOrEmpty } from '../../../Utils/stringUtils';
import Dropdown from '../../_widgets/Dropdown/Dropdown';
import DatePickerWrapper from '../../_widgets/Datepicker/DatePicker';
import { useRef } from 'react';
import MainLoader from '../../_widgets/Loader/MainLoader';
const AddEditExpense = ({ modelShow, handleClose, handleSave, buttonLoading, expenseData, setExpenseData, fetchExpensiveCategory, getExpenseCategoryList, setCategorySearch, loading, selectLoading }) => {

    return (
        <BasicModal
            show={modelShow}
            handleClose={handleClose}
            size='lg'
            footer
            heading={expenseData?.id ? "Edit Expense Category" : "Add Expense Category"}
            button1Click={handleSave}
            loading={buttonLoading}
            button1Text={'Save'}
            minH={"60vh"}
        >
            {
                loading === true ? <MainLoader /> : <Row>
                    <Col lg={4}>
                        <Input
                            inputType="currency"
                            isRequired
                            placeholder="Amount"
                            label="Amount"
                            allowDecimals={true}
                            currencyPrefix={true}
                            value={expenseData?.amount}
                            onValueChange={(val) => {
                                if (val <= 99999 || isNullOrEmpty(val)) {
                                    setExpenseData({ ...expenseData, amount: val });
                                } else {
                                    warn(MAX_AMT_LIMIT_REACHED);
                                }
                            }}
                        />
                    </Col>
                    <Col lg={4}>
                        <Dropdown
                            isRequired
                            label="Category"
                            placeholder="Select Category"
                            value={expenseData.exp_cat_id}
                            isLoading={selectLoading}
                            onKeyDown={(e) => setCategorySearch(e.target.value)}  // Use onKeyDown but get the value from the ref
                            options={fetchExpensiveCategory()}
                            name={expenseData?.exp_cat_id}
                            onChange={value => setExpenseData({ ...expenseData, exp_cat_id: value })}

                        />

                    </Col>

                    <Col lg={3}>
                        <div className="date_range_picker">
                            <DatePickerWrapper
                                label="Date"
                                isRequired
                                selected={expenseData?.date}
                                value={expenseData?.date}
                                onChange={(date) => setExpenseData({ ...expenseData, date: date })}
                            />
                        </div>
                    </Col>
                    <Col lg={4}>
                        <div>
                            <Input
                                isRequired
                                label="Remark"
                                placeholder="Remark"
                                id="Remark"
                                suffixIcon='Remark'
                                value={expenseData.description}
                                onChange={e => e.target.value.length > 50 ? warn(maximumLengthAllowed(50)) : setExpenseData({ ...expenseData, description: e.target.value.slice(0, 50) })}

                            />
                        </div>
                    </Col>
                </Row>
            }

        </BasicModal>
    );
}
export default AddEditExpense;