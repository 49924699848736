import React from 'react';
import NavBar from '../../_widgets/NavBar/NavBar';
import AnimatedPage from '../../AnimatedPage/AnimatedPage';
import CustomTooltip from '../../_widgets/Tooltip/CustomTooltip';
import * as stringUtils from "../../../Utils/stringUtils";
import CustomPagination from '../../_widgets/Pagination/Pagination';
import Text from '../../_widgets/Text/Text';
import Button from '../../_widgets/Button/Button';
import IconResource from '../../../Wrapper/IconResource/IconResource';
import { Table, Col, Row } from 'react-bootstrap';
import noData from "../../../Assets/img/noData.png";
import { formattedValue, isSuperAdmin } from '../../../Services/common';
import MainLoader from '../../_widgets/Loader/MainLoader';
import DateRangePicker from '../../_widgets/DateRangePicker/DateRangePicker';
import Input from '../../_widgets/Input/Input';
import AddEditCategory from './AddEditCategory';
import { getColorFromStatus } from '../../../Utils/appUtils';
import ModalImport from '../../_widgets/Modal/ModalImport';
const ExpenseCategoryUi = ({ setModalShow, modelShow, expenseCategoryList, loading, onPageChange, searchQuery, setSearchQuery, getExpenseCategoryList, dates, setDates, datePickerStartRef, datePickerEndRef, buttonLoading, setExpenseCategoryData, handleClose, handleSave, expenseCategoryData, statusHandler, openImportModal, importModal, setImportModal, importList, importListing, selectedRows, setSelectedRows, exportList }) => {
    return (
        <>
            <AnimatedPage>
                <AddEditCategory
                    buttonLoading={buttonLoading}
                    handleSave={handleSave}
                    modelShow={modelShow}
                    handleClose={handleClose}
                    expenseCategoryData={expenseCategoryData}
                    setExpenseCategoryData={setExpenseCategoryData} />
                <ModalImport
                    show={importModal}
                    handleClose={() => setImportModal(false)}
                    buttonLoading={buttonLoading}
                    handleSaveClick={exportList}
                    loading={loading}
                    name="Expense Category(s)"
                    data={importList}
                    importListing={importListing}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
                <NavBar
                    button2={!isSuperAdmin() && "Import Expense Category"}
                    button="Add Expense Category"
                    buttonIcon="hospital"
                    heading="Expense Category"
                    onClick={() => {
                        document.body.classList.toggle("side_active");
                        document.body.classList.toggle("side_active_mb");
                    }}
                    setModalShow={setModalShow}
                    button2onclick={openImportModal}

                    Icon="menu"
                />
                <div className="patient_list white_card">
                    <div className="top_bar">
                        <Text type="h2" text="All Expense Category" />
                        <Row className="search_filter">
                            <Col lg={4}>
                                <div>
                                    <Input
                                        placeholder="Search"
                                        id="searchInput"
                                        suffixIcon='search'
                                        value={searchQuery}
                                        onChange={(e) => setSearchQuery(e.target.value)}
                                        onClickIconSuff={() => getExpenseCategoryList()}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                getExpenseCategoryList()
                                            }
                                        }}
                                        iconTooltip='Search'
                                    />
                                </div>
                            </Col>
                            <Col lg={8}>
                                <div className="date_range_picker">
                                    <DateRangePicker
                                        startDate={dates?.startDate}
                                        setStartDate={(startDate) => setDates({ ...dates, startDate })}
                                        endDate={dates?.endDate}
                                        setEndDate={(endDate) => setDates({ ...dates, endDate })}
                                        onChange={(startDate, endDate) => setDates({ startDate, endDate })}
                                        datePickerStartRef={datePickerStartRef}
                                        datePickerEndRef={datePickerEndRef}
                                        showDateShortcuts={true}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="custom_table position-relative">
                        {loading ? <MainLoader /> : expenseCategoryList?.data?.length > 0 ? (
                            <>
                                <div>
                                    <Table responsive hover>
                                        <thead>
                                            <tr>
                                                <th> Name</th>
                                                <th> Description</th>

                                                <th>Status </th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {expenseCategoryList?.data?.map((data) => {
                                                return (
                                                    <tr key={data.id}>
                                                        <td>
                                                            <CustomTooltip text={data.name} placement={"top"}>
                                                                {stringUtils.slice(data.name, 20)}
                                                            </CustomTooltip>
                                                        </td>
                                                        <td>
                                                            <CustomTooltip text={data.description} placement={"top"}>
                                                                {stringUtils.slice(data.description, 20) || ''}
                                                            </CustomTooltip>
                                                        </td>

                                                        <td>
                                                            <CustomTooltip
                                                                type="popover"
                                                                button={
                                                                    <div className="p-1">
                                                                        <Button
                                                                            isLoading={buttonLoading}
                                                                            onClick={() => statusHandler(data)}
                                                                            buttonType="primary"
                                                                            text={data.status === "ACTIVE" ? "Deactivate" : "Activate"}
                                                                        />
                                                                    </div>
                                                                }
                                                                placement="top"
                                                            >
                                                                <div className={`status status__${getColorFromStatus(data.status)}`}>
                                                                    <Text text={stringUtils.capitalizeFirstLetter(data.status)} />
                                                                </div>
                                                            </CustomTooltip>
                                                        </td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2 justify-content-center">
                                                                <CustomTooltip text="Edit" placement={"top"}>
                                                                    <Button
                                                                        className="p-1 bg-transparent border-0"
                                                                        icon={
                                                                            <IconResource type={buttonLoading === `edit${data.id}` ? 'loader' : 'edit'} color="var(--light-text)" />
                                                                        }
                                                                        onClick={() => {
                                                                            setExpenseCategoryData(data)
                                                                            setModalShow(true)
                                                                        }}
                                                                    />
                                                                </CustomTooltip>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </Table>
                                </div>

                                <CustomPagination
                                    activePage={expenseCategoryList?.current_page}
                                    onPageChange={onPageChange}
                                    pageLength={expenseCategoryList?.per_page}
                                    totalData={expenseCategoryList?.total}
                                    lastPage={expenseCategoryList?.last_page}
                                />
                            </>
                        ) : (
                            <>
                                <Table responsive hover>
                                    <thead>
                                        <tr>
                                            <th> Name</th>
                                            <th> Description</th>

                                            <th>Status </th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody></tbody>
                                </Table>

                                <div className="no_data position-relative">
                                    <img
                                        src={noData}
                                        className="img-fluid"
                                        style={{ width: "300px" }}
                                    />
                                    <Text
                                        type="h2"
                                        text="There is no data to display. Try re-setting filters!"
                                    />
                                    <Button
                                        buttonType="primary"
                                        text="Add Expense Category"
                                        prefixIcon={<IconResource type="hospital" size={18} />}
                                        onClick={() => setModalShow(true)}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </AnimatedPage>
        </>
    )
}
export default ExpenseCategoryUi;