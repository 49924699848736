import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
import './dropdown.scss';

const Dropdown = ({ options, isDisabled, isClearable, isLoading, isSearchable, name, isRtl, onChange, label, placeholder, isMulti, isRequired, serverSearchable, ...restProps }) => {
    const [selectedOption, setSelectedOption] = useState(isMulti ? [] : null);

    useEffect(() => {
        if (name) {
            if (isMulti && Array.isArray(name)) {
                const foundOptions = options?.filter(option => name?.includes(option?.value));
                if (foundOptions) {
                    setSelectedOption(foundOptions);
                }
            } else {
                const foundOption = options?.find(option => option?.value == name);  // eslint-disable-line eqeqeq
                if (foundOption) {
                    setSelectedOption(foundOption);
                }
            }
        }
    }, [name, options, isMulti]);

    const handleChange = (selectedOption) => {
        if (onChange) {
            onChange(isMulti
                ? (serverSearchable ? selectedOption : selectedOption?.map(option => option.value))
                : (selectedOption ? (serverSearchable ? selectedOption : selectedOption.value) : ''));
        }
        setSelectedOption(selectedOption);
    };

    const customStyles = {
        control: (provided, state) => ({
            ...provided,
            boxShadow: state.isFocused ? null : provided.boxShadow,
            borderColor: state.isFocused ? '#FF5E15' : provided.borderColor,
            '&:hover': {
                borderColor: state.isFocused ? 'var(--primary)' : provided.borderColor,
            }
        })
    };

    return (
        <>
            {label && <Form.Label aria-label={label} className={isRequired ? "required" : ""}>{label}</Form.Label>}
            <Select
                className="basic-single mb-3"
                classNamePrefix="select"
                defaultValue={selectedOption}
                isDisabled={isDisabled}
                isLoading={isLoading}
                isClearable={isClearable}
                isRtl={isRtl}
                isSearchable={isSearchable}
                name={name}
                menuPosition="fixed"
                options={options}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
                value={isMulti ? selectedOption : options?.filter(({ value }) => value == name)}
                onChange={handleChange}
                placeholder={`Select ${placeholder || "Value"}`}
                styles={customStyles}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 0,
                    border: 0,
                    colors: {
                        ...theme.colors,
                        primary25: 'var(--border-light)',
                        primary: 'var(--primary)',
                    },
                })}
                isMulti={isMulti}
                {...restProps}
            />
        </>
    );
};

export default Dropdown;
