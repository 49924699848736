import moment from 'moment/moment';

export const changeDateFormatForServer = (date) => {
    return date ? moment(date).format("YYYY-MM-DD") : '';
}
export const getTimeFormat = (date) => {
    return date ? moment(date).format("hh:mm A") : '';
}
export const changeDateFormatForShow = (date) => {
    return date ? moment(date).format("DD/MM/YYYY") : '';
}
export const changeDateFormatForDisplay = (date) => {
    return date ? moment(date).format("DD MMM YYYY") : '';
}
export const changeDateTimeFormatForDisplay = (date) => {
    return date ? moment(date).format("DD MMM YYYY hh:mm A") : '';
}
// export const changeDateTimeFormatForDisplay = (date) => {
//     return date ? moment(date).format("DD MMM YYYY HH:mm A") : '';
// }

export function tomorrowDate() {
    return moment().add(1, 'days').format("DD/MM/YYYY");
}

export function nowDateTime() {
    return moment().format("YYYY-MM-DD HH:mm:ss");
}

export function dateTime(hours = 0, date = moment()) {
    return moment(date).add(hours, 'hours').format("YYYY-MM-DD HH:mm:ss");
}

export function formatAndAddDate(days, format = "DD-MM-YYYY", date = new Date()) {
    return moment(date, format).add(days, 'days')
}

export function momentToDate(date) {
    return moment(date)?.toDate()
}

export function momentYear() {
    return moment().year()
}

export function isBetween(beginTime, startTime, endTime) {
    return moment(beginTime).isBetween(startTime, endTime) || (moment(beginTime).isSame(moment(startTime)))
}

export function momentResult(input) {
    return moment(input)
}

export function timeFormat(time, format = "h:mm a") {
    return moment(time, format)
}

export function momentDuration(input) {
    return moment.duration(input)
}

export function isTodayDate(date) {
    return moment(date, "YYYY-MM-DD").isSame(moment(), 'day');
}

export function isPastDate(date) {
    return moment(date, "YYYY-MM-DD").isBefore(moment(), 'day');
}

export function isFutureDate(date) {
    return moment(date, "YYYY-MM-DD").isAfter(moment(), 'day');
}


export const isSameDay = (date1, date2) =>
    date1?.getFullYear() === date2?.getFullYear() &&
    date1?.getMonth() === date2?.getMonth() &&
    date1?.getDate() === date2?.getDate();


export function calculateAge(dob) {
    const birthDate = new Date(dob);
    const currentDate = new Date();

    const diffTime = currentDate - birthDate;
    const diffDate = new Date(diffTime);

    const ageInYrs = diffDate.getUTCFullYear() - 1970;
    const ageInMonths = diffDate.getUTCMonth();
    const ageInDays = diffDate.getUTCDate() - 1;

    let age;
    if (ageInYrs > 0) {
        age = ageInYrs + "Y";
    } else if (ageInMonths > 0) {
        age = ageInMonths + "M";
    } else {
        age = ageInDays + "D";
    }

    return age;
}
