import React from 'react';
import BasicModal from '../../_widgets/Modal/BasicModal';
import { maximumLengthAllowed } from '../../../Constants/stringConstants';
import { Row, Col } from 'react-bootstrap';
import { warn } from '../../../Wrapper/toast/toast';
import Input from '../../_widgets/Input/Input';
const AddEditCategory = ({ modelShow, handleClose, handleSave, buttonLoading, expenseCategoryData, setExpenseCategoryData }) => {

    return (
        <BasicModal
            show={modelShow}
            handleClose={handleClose}
            size='md'
            footer
            heading={expenseCategoryData?.id ? "Edit Expense Category" : "Add Expense Category"}
            button1Click={handleSave}
            loading={buttonLoading}
            button1Text={'Save'}
        >
            <Row>
                <Col lg={5}>
                    <Input
                        label="Category Name"
                        placeholder="Enter Category's Name"
                        isRequired
                        value={expenseCategoryData.name}
                        onChange={e => e.target.value.length > 50 ? warn(maximumLengthAllowed(50)) : setExpenseCategoryData({ ...expenseCategoryData, name: e.target.value.slice(0, 50) })}
                    />
                </Col>
                <Col lg={6}>
                    <Input
                        label="Description"
                        placeholder="Enter Description"
                        isRequired
                        value={expenseCategoryData.description}
                        onChange={e => e.target.value.length > 50 ? warn(maximumLengthAllowed(50)) : setExpenseCategoryData({ ...expenseCategoryData, description: e.target.value.slice(0, 50) })}
                    />
                </Col>



            </Row>

        </BasicModal>
    );
}
export default AddEditCategory;