// response.js
import * as commonServices from './common';
import * as toast from "../Wrapper/toast/toast";
import axios from 'axios';
import { SUCCESS_CODE, UNAUTHORIZED_ERROR_CODE, RESOURCE_CREATED, ERROR_CODE } from '../Constants/responseCodes';
import { trackAPITime } from '../helpers/analytics';

const getErrorResponse = response => {
    return { isSuccess: false, message: response?.data?.message || "Something went wrong!" }
}

export function handleResponse(resp, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, SUCCESS_CODE);

    let response = resp ?? {};

    if (response?.data) {
        if (response?.status === SUCCESS_CODE || response?.status === RESOURCE_CREATED) {
            response.data.isSuccess = true;
            return response.data;
        } else if (response?.status === UNAUTHORIZED_ERROR_CODE) {
            commonServices.clearLocalStorageData();
            window.location.href = '/';
            toast.warn(response?.data?.message);
            return;
        } else {
            return getErrorResponse(response);
        }
    } else {
        return getErrorResponse(response);
    }
};

export function handleError(err, startTime, url) {

    const endTime = Date.now(); // Capture the end time
    const apiTime = endTime - startTime; // Calculate API time
    trackAPITime(apiTime, url, "ERROR");

    let error = err ?? {};

    if (axios.isCancel(error)) {

        return;
    }
    let { response } = error;

    if (response?.data) {
        if (response?.status === UNAUTHORIZED_ERROR_CODE) {
            commonServices.clearLocalStorageData();
            window.location.href = '/';
            toast.warn(response?.data?.message);
            return;
        } else if (response?.status === ERROR_CODE) {
            response.data.isSuccess = false;
            return response.data;
        } else {
            return getErrorResponse(response);
        }
    } else {
        return getErrorResponse(response);
    }
};
