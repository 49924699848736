import { useEffect, useState, useRef, useCallback } from 'react';
import BasicModal from '../Modal/BasicModal';
import MainLoader from '../Loader/MainLoader';
import Input from '../Input/Input';
import { formattedValue } from '../../../Services/common';
import { Table } from 'react-bootstrap';
import * as commonServices from '../../../Services/common'
import * as storageConstants from '../../../Constants/storageConstant';
const ModalImport = ({
    show,
    handleClose,
    buttonLoading,
    data,
    handleSaveClick,
    loading,
    name,
    importListing,
    selectedRows,
    setSelectedRows
}) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearchChanged, setIsSearchChanged] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [allData, setAllData] = useState([]);
    const [originalData, setOriginalData] = useState([]);
    const [originalPage, setOriginalPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [selectAll, setSelectAll] = useState(false);

    const payload = {
        q: searchQuery,
        page: activePage,
    };

    useEffect(() => {
        if (show) {
            importListing(payload);
        } else {
            resetModalState();
        }
    }, [show, activePage, isSearchChanged]);

    useEffect(() => {
        if (data) {
            const newData = data?.data || [];
            const newDataSet = new Set(newData.map(item => item.id));

            let selectedData = commonServices.getLocalData(storageConstants.SELECTED_ROW);
            setAllData(oldData => {

                let existingSelectedIds = new Set(oldData.map(item => item.id));
                let filteredNewData = selectedData?.filter(item => !existingSelectedIds.has(item.id));
                return [...filteredNewData, ...allData?.filter(item => !newDataSet.has(item.id)), ...newData];
            })

            if (searchQuery.trim() === '') {

                setOriginalData(prevOriginal => {
                    const existingIds = new Set(prevOriginal.map(item => item.id));

                    const filteredNewData = newData.filter(item => !existingIds.has(item.id));

                    return [...prevOriginal, ...filteredNewData];
                });

            }
            setLastPage(data?.last_page || 1);
        }
    }, [data]);

    const resetModalState = () => {
        setAllData([]);
        setActivePage(1);
        setLastPage(1);
        setSelectAll(false);
        setSearchQuery('');
        setIsSearchChanged(false);
        setSelectedRows([]);
        commonServices.storeLocalData(storageConstants.SELECTED_ROW, []);
    };

    useEffect(() => {
        if (selectAll) {
            const allIds = allData.map(d => d.id);
            setSelectedRows(prevSelected => Array.from(new Set([...prevSelected, ...allIds])));

        } else {
            let selectedData = commonServices.getLocalData(storageConstants.SELECTED_ROW).map(d => d.id);
            const allIds = allData.map(d => d.id)
            let allSelectedRow = new Set([...selectedData, ...allIds])

            if (allIds.length === selectedRows.length) {

                setSelectedRows(prev => prev?.filter(r => !allIds.includes(r)));

            }
            //   setSelectedRows(prev => prev?.filter(r => !allIds.includes(r)));
        }
    }, [selectAll]);

    const observer = useRef(null);

    const lastElementRef = useCallback(
        (node) => {
            if (observer.current) observer.current.disconnect();

            observer.current = new IntersectionObserver(
                (entries) => {

                    if (entries[0].isIntersecting && !loading && activePage < lastPage) {
                        setActivePage((prevPage) => prevPage + 1);
                    }
                },
                {
                    rootMargin: '0px',
                    threshold: 1.0,
                }
            );

            if (node) observer.current.observe(node);
        },
        [loading, activePage, lastPage]
    );

    const handleSearchChange = e => {
        setSearchQuery(e.target.value);
    };

    const handleSearchKeyDown = () => {
        if (searchQuery.trim() === '') {
            var selectedData = []
            allData.map((i) => {
                if (selectedRows.includes(i.id)) {
                    selectedData.push(i)
                }
            })


            setAllData(originalData);
            setActivePage(originalPage);
        } else {
            setOriginalPage(activePage);
            setActivePage(1);
            setAllData([]);
        }
        setIsSearchChanged(!isSearchChanged);
    };

    const handleCheckboxChange = (e, id) => {
        let selectedData = commonServices.getLocalData(storageConstants.SELECTED_ROW);
        if (e.target.checked) {

            let isAvailable = selectedData?.filter((i) => {
                return id == i.id;
            })

            if (!isAvailable.length > 0) {
                allData.map((i) => {
                    if (i.id == id) {
                        commonServices.storeLocalData(storageConstants.SELECTED_ROW, [...selectedData, i]);
                    }
                })
            }
            setSelectedRows(prevRows => Array.from(new Set([...prevRows, id])));
        } else {
            let isAvailable = selectedData.filter((i) => {
                return id != i.id;
            })

            if (isAvailable) {
                let storedData = isAvailable
                commonServices.storeLocalData(storageConstants.SELECTED_ROW, storedData);
            }


            let data = selectedRows.filter((i) => {
                return i !== id
            })
            let selectedRowData = []
            allData.map((i) => {
                if (data.includes(i.id)) {
                    selectedRowData.push(i)
                }
            })

            setSelectedRows(data)

            setSelectAll(false);
        }
    };

    const handleSelectAllChange = e => {
        // commonServices.storeLocalData(storageConstants.SELECTED_ROW, allData)
        if (e.target.checked) {
            let data = commonServices.getLocalData(storageConstants.SELECTED_ROW)
            let allId = data.map(i => i.id)
            let remainingData = sortedData.filter((a) => !allId.includes(a.id));

            if (remainingData.length === sortedData.length) {
                commonServices.storeLocalData(storageConstants.SELECTED_ROW, sortedData);
            }
            else {
                commonServices.storeLocalData(storageConstants.SELECTED_ROW, [...sortedData, ...remainingData]);
            }


        }
        else {
            commonServices.storeLocalData(storageConstants.SELECTED_ROW, []);

        }

        setSelectAll(e.target.checked);

    };

    const sortedData = allData.sort((a, b) => {

        const isCheckedA = selectedRows?.includes(a.id);
        const isCheckedB = selectedRows?.includes(b.id);

        if (isCheckedA === isCheckedB) return 0;
        return isCheckedA ? -1 : 1;
    });

    return (
        <BasicModal
            show={show}
            size="xl"
            handleClose={handleClose}
            heading={`Import ${name || ''}`}
            footer
            minH={"50vh"}
            button2Text="Import"
            button2Click={handleSaveClick}
            loading={buttonLoading}
        >
            <div className="patient_list white_card">
                <div className="top_bar">
                    <div className="d-flex search_filter justify-content-end w-100">
                        <div>
                            <Input
                                placeholder="Search"
                                id="searchInput"
                                suffixIcon='search'
                                value={searchQuery}
                                onChange={handleSearchChange}
                                onClickIconSuff={handleSearchKeyDown}
                                onKeyDown={e => e.key === 'Enter' && handleSearchKeyDown()}
                                iconTooltip='Search'
                            />
                        </div>
                    </div>
                </div>
                {loading && activePage === 1
                    ? (<MainLoader />)
                    : <>
                        <Table responsive hover>
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <input
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={handleSelectAllChange}
                                        />
                                    </th>
                                    <th scope="col">Name</th>
                                    {name === 'Service(s)' && <th scope="col">Price</th>}
                                    <th scope="col">Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map(d => (
                                    <tr key={d?.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                checked={selectedRows?.includes(d?.id)}
                                                value={d?.id}
                                                onChange={e => handleCheckboxChange(e, d?.id)}
                                            />
                                        </td>
                                        <td>{name === 'Service(s)' ? (d?.name || '').toUpperCase() || '-' : (d?.name || '-')} </td>
                                        {name === 'Service(s)' && <td>{d?.price ? formattedValue(d?.price, true) : '-'}</td>}
                                        <td>{d?.description || '-'}</td>
                                    </tr>
                                ))}
                                <tr ref={lastElementRef}>
                                    {activePage < lastPage && <td colSpan="4" style={{ textAlign: 'center' }}>
                                        Loading more {name}...
                                    </td>}
                                </tr>
                            </tbody>
                        </Table>
                    </>
                }
            </div>
        </BasicModal>
    );
};

export default ModalImport;
